<template>  
  <section>

    <div v-if="!loading">
   
        <button
          v-on:click="loginSubmit"
          class="mt-2 btn btn-fill btn-block btn-primary"
        >
          {{ $t("LOGIN.SUBMIT") }}
        </button>
      
    </div>
    <div class="text-center" v-if="loading">
      <InformationMessage class="mb-2" type="information">
        {{$t('LOGIN.PROCESSING')}}
      </InformationMessage>
      <img src="/img/loading.gif" class="loading50" />
    </div>

  </section>
</template>
<script>
export default {
  props: ["loginInfo"],
  components: { 
    InformationMessage: () => import("@/components/Layout/InformationMessage")
  },
  data() {
    return {
      loading: true,
      auth_uuid: '',
      errorMessage: ""
    };
  },
  methods: {
    loginSubmit: function() {
      this.loading = true;
      let self = this;
      self.$emit("loading",true);
      this.$http
        .post(self.user.hostname + "/authenticate", {
          email: this.user.email,
          auth_method: this.loginInfo.auth_method,
          auth_type: this.loginInfo.auth_type,
          client: "addin"
        })
        .then(function(result) {
          self.auth_uuid = result.data.auth_uuid;
          self.makeRequest(result.data.request_url);
        })
        .catch(function(error) {
          self.$emit("loading",false);
          self.loading = false;
          if(error.toString() == "Error: fail_4_4") return;
          if(error.response != undefined)
          {
            self.$noty.error(self.$t(error.response.data));
          }
        });
    },
    makeRequest: function(url) {
      this.loading = true;
      let self = this;
      this.$http
        .get(url)
        .then(function() {
          self.verifyAuthentication();
        })
        .catch(function(error) {
          self.$emit("loading",false);
          self.loading = false;
          if(error.toString() == "Error: fail_4_4") return;
          self.$noty.error(self.$t(error.response.data));
        });
    },
    verifyAuthentication: function() {
      let self = this;
      this.$http
      .get(self.user.hostname + "/authenticate/result/" + this.auth_uuid)
      .then(function(result) {
        self.$emit('authenticated',result.data.token);
      })
      .catch(function(error) {
        self.$emit("loading",false);
        self.loading = false;
        if(error.toString() == "Error: fail_4_4") return;
        self.$noty.error(self.$t(error.response.data));
      });
    }
  },
  mounted: function() {
    this.loading = false;
    this.loginInfo.auth_method = "";
  }
};
</script>
<style></style>
